.hero {
    @apply mx-auto max-w-screen-max;
    height: 87%;
}

.hero__text-container {
    max-width: 100vw;
}

.hero__text {
    @apply flex flex-col items-center justify-center h-full mx-auto text-center px-4;
}

.hero__text-pre {
    @apply font-bold text-xl uppercase font-serif;
}

.hero__text-title {
    @apply font-bold text-5xl uppercase; 
}

.hero__text-description {
    @apply leading-normal py-10 font-serif;
}

.button {
    @apply uppercase text-sm;
}

@media (max-width: 320px) {
    
    .hero__text-title {
        font-size: 2rem;
    }

    .hero__text-container {

        & .button {
            @apply mb-5;
        }
    }
}

/* iPhone 5 Retina regardless of IOS version */
@media (device-height : 568px) 
    and (device-width : 320px) 
    and (-webkit-min-device-pixel-ratio: 2) {

        .hero__text-title {
            font-size: 2rem;
        }

        .hero__text-container {

            & .button {
                @apply mb-5;
            }
        }
}

@screen sm {

    .hero {
        height: 87%;
    }

    .hero__text-container {
        @apply pt-0;
    }

    .button {
        @apply mr-5;
    }
}

@screen lg { 

    .hero__text-container {
        @apply max-w-xl;
    }
}

@screen xl {
    .hero__text {
        @apply relative;
    }

    .hero__text-container {
        @apply absolute bottom-20 right-20 text-left max-w-lg;
    }

    .hero__text-title {
        @apply p-0;
    }
}