.image-aside {
    @apply mx-auto max-w-screen-max pt-0;
}

.image-aside__container {
    @apply flex flex-col mx-auto;
}

.image-aside__pre {
    @apply uppercase text-brand-accentBlue;
}

.image-aside__title {
    @apply uppercase text-brand-secondary font-bold !important;
}

.image-aside__description {
    @apply py-5;
}

.image-aside__content {
    padding: clamp(1rem, 2vw, 3rem);
    padding-left: 0;
}

.image-aside__text {

    & p {
        @apply my-5 text-brand-black;
    }
}

.image-aside__image {
    @apply flex flex-col items-center justify-center text-center;
}

.image-aside__image-caption {
    @apply mt-10;
}

@screen lg {
    
    .image-aside__container {
        @apply flex-row;
    }
    
    .image-aside__content,
    .image-aside__image-container {
        @apply w-50;
    }

    .image-aside__content {
        @apply flex flex-col items-start justify-center;
    }

    .image-aside__image-container {
        @apply flex items-center justify-center;
    }
}
