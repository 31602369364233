/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v23-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v23-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('../fonts/montserrat-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/montserrat-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/montserrat-v23-latin-700.woff') format('woff'), /* Modern Browsers */
            url('../fonts/montserrat-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/montserrat-v23-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/inter-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inter-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inter-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inter-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inter-v8-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/inter-v8-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/inter-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/inter-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/inter-v8-latin-700.woff') format('woff'), /* Modern Browsers */
        url('../fonts/inter-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/inter-v8-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}

/*
clamp() enables selecting value within a range of values between 
a defined minimum and maximum. It takes three parameters: 
a minimum value, a preferred value, and a maximum allowed value. 
*/

.heading-1 {
    @apply font-sans;
    font-size: clamp(2rem, 5vw, 4rem);
    line-height: 1.125;
}

.heading-2 {
    @apply font-sans;
    font-size: clamp(1.75rem, 4vw, 2.5rem);
    line-height: 1.4;
}

.heading-3 {
    @apply font-sans;
    font-size: clamp(1.5rem, 4vw, 2rem);
    line-height: 2;
}

.heading-4 {
    @apply font-sans;
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    line-height: 1.35;
}

.heading-5 {
    @apply font-sans;
    font-size: 0.875rem;
    letter-spacing: 0.2em;
}

.heading-6 {
    @apply font-sans;
    font-size: 0.875rem;
    letter-spacing: 0.2em;
}

.p {
    @apply font-sans;
    letter-spacing: 0.04em;
    line-height: 2;
}

.p-base {
    font-size: clamp(1rem, 3vw, 1.125rem);
}

.p-small {
    font-size: clamp(0.875rem, 3vw, 1rem);
}
