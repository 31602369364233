.full-width-text {
  @apply mx-auto max-w-screen-max text-left mt-10;
}

.full-width-text__pre {
  @apply uppercase text-brand-accentBlue;
}

.full-width-text__title {
  @apply uppercase text-brand-secondary font-bold;
}

.full-width-text__text {

  & p {
    @apply my-5 text-brand-black
  }
}

@screen xl {

  .full-width-text {
    @apply max-w-80 text-center;
  }
}

@screen max {

  .full-width-text {
    @apply max-w-50;
  }
}