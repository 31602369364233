footer {
    @apply bg-white flex flex-col items-center mx-auto max-w-screen-max;
}

.footer__container {
    @apply max-w-8xl mx-auto w-full flex flex-col justify-between items-center;
}

.footer__links ul {
    @apply flex flex-col items-center mb-8;
}

.footer__links li {
    @apply my-3;
}

.footer__links-actual {
    @apply uppercase;
}

.footer__links-actual:hover {
    @apply opacity-70;
}

.footer__copyright {
    @apply text-center;
}


@screen lg {

    .footer__logo {
        @apply flex w-full items-center justify-center text-center;
    }

    .footer__links {
        @apply flex-wrap w-full;
    }

    .footer__links ul {
        @apply flex-row flex-wrap justify-evenly mb-0;
    }

    .footer__nav-link {
        @apply text-center text-sm;
    }

    .footer__copyright {
        @apply text-sm;
    }
}

@screen xl {

    .footer__nav-link,
    .footer__copyright {
        @apply text-base;
    }

}
