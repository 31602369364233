.nav-container {
    @apply w-full h-32;
}

.nav-link {
    @apply text-left uppercase text-base;
}

.nav-link:hover {
    @apply opacity-70;
}

/* .nav-link--active {
    @apply text-brand-primary;
} */

.mobile-navigation-open {
    @apply opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right z-50;
}

.mobile-navigation-closed {
    @apply opacity-0 scale-95 absolute inset-x-0 p-2 transition transform origin-top-right;
    top: -100vh;
}


@screen xs {
    .tweak {
        @apply mt-5 -mr-2.5
    }
}

@screen sm {
    .tweak {
        @apply -mr-0
    }
}

@screen md {

    .tweak {
        @apply mt-0;
    }
}

@screen lg {
    .nav-link {
        @apply text-center mx-6;
    }

    .nav-link:last-of-type {
        @apply mr-0 ml-6;
    }

    .mobile-navigation-closed,
    .mobile-navigation-open {
        @apply hidden;
    }
}
